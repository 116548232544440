import { Routes } from '@angular/router';

import { PageNotFoundComponent } from '@core/components/page-not-found/page-not-found.component';
import { ReportDashboardComponent } from './reports/report-dashboard/report-dashboard.component';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@root/environments/environment';
import { GroupGuard } from './guards/group.guard';

export enum AppPaths {
  iDLTConnect_menuItem = 'iDLTConnect_menuItem',
  admin_menuItem = 'admin_menuItem',
  report = 'report',
  report_detail = 'report-detail',
  ai_report = 'ai-report',
  data_extraction = 'data-extraction',
  canoe_data_extraction = 'canoe-data-extraction',
  admin_prompt = 'admin/prompt',
  admin_document_type = 'admin/document-type',
  fund_management = 'fund-management',
  subscription_management = 'subscription-management',
  capital_event_management = 'capital-event-management',
}

export const routes: Routes = [
  // common routes
  { path: '', component: ReportDashboardComponent, pathMatch: 'full',
    title: '',
    data: {
      title: '',
      action: '',
      breadCrumbs: []
    },
  },

  //reporting routes
  {
    path: AppPaths.report,
    loadComponent: () => import('./reports/report/report.component').then(m => m.ReportComponent),
    title: 'Capital Event',
    data: {
      title: 'Capital Event',
      action: 'report',
      breadCrumbs: ['Capital Event']
    },
    canActivate: [GroupGuard],
  },
  {
    path: AppPaths.report_detail,
    loadComponent: () => import('./reports/report-detail/report-detail.component').then(m => m.ReportDetailComponent),
    title: 'Capital Event Letter',
    data: {
      title: 'Capital Event Letter',
      action: 'report-detail',
      breadCrumbs: ['Capital Event Letter']
    },
    canActivate: [GroupGuard],
  },
  {
    path: AppPaths.ai_report,
    loadComponent: () => import('./reports/ai-report/ai-report.component').then(m => m.AiReportComponent),
    title: 'Document Data Extraction',
    data: {
      title: 'Document Data Extraction',
      action: 'ai-report',
      breadCrumbs: ['Document Data Extraction']
    },
    canActivate: [GroupGuard],
  },
  {
    path: AppPaths.data_extraction,
    loadComponent: () => import('./reports/data-extraction/data-extraction.component').then(m => m.DataExtractionComponent),
    title: 'Data extraction',
    data: {
      title: 'Data extraction',
      action: 'data-extraction',
      breadCrumbs: ['Data Extraction']
    },
    canActivate: [GroupGuard],
  },
  {
    path: AppPaths.canoe_data_extraction,
    loadComponent: () => import('./reports/data-extraction/canoe/canoe/canoe.component').then(m => m.CanoeComponent),
    title: 'Canoe Data Extraction',
    data: {
      title: 'Canoe Data Extraction',
      action: 'canoe',
      breadCrumbs: ['Canoe Data Extraction']
    },
    canActivate: [GroupGuard],
  },
  {
    path: AppPaths.admin_prompt,
    loadComponent: () => import('./reports/admin/prompt/prompt.component').then(m => m.PromptComponent),
    title: 'Admin prompt',
    data: {
      title: 'Admin prompt',
      action: 'prompt',
      breadCrumbs: ['Admin prompt']
    },
    canActivate: [GroupGuard],
  },
  {
    path: AppPaths.admin_document_type,
    loadComponent: () => import('./reports/admin/document-type/document-type.component').then(m => m.DocumentTypeComponent),
    title: 'Document type',
    data: {
      title: 'Document type',
      action: 'document-type',
      breadCrumbs: ['Admin', 'Document type']
    },
    canActivate: [GroupGuard],
  },

  //iDLT routes
  { 
    path: AppPaths.fund_management, 
    loadComponent: () => import('@funds/components/funds-screen/funds-screen.component').then(m => m.FundsScreenComponent),
    title: 'Fund Management',
    data: {
      title: 'Fund Management',
      action: 'fund',
      breadCrumbs: ['iDLT Connect', 'Fund Management']
    },
    canActivate: [GroupGuard],
  },
  { 
    path: AppPaths.subscription_management, 
    loadComponent: () => import('@investments/components/investments-screen/investments-screen.component').then(m => m.InvestmentsScreenComponent),
    title: 'Subscription Management',
    data: {
      title: 'Subscription Management',
      action: 'subscription',
      breadCrumbs: ['iDLT Connect', 'Subscription Management']
    },
    canActivate: [GroupGuard],
  },
  { 
    path: AppPaths.capital_event_management, 
    loadComponent: () => import('@capevents/components/capital-events-screen/capital-events-screen.component').then(m => m.CapitalEventsScreenComponent),
    title: 'Capital Event Management',
    data: {
      title: 'Capital Event Management',
      action: 'capital_event',
      breadCrumbs: ['iDLT Connect', 'Capital Event Management']
    },
    canActivate: [GroupGuard],
  },
  { path: '**', redirectTo: '' }
];